import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Logo from '../assets/images/logo-white.svg';
import LogoBlack from '../assets/images/logo-black.svg';
import layoutStyles from './Layout.module.scss';

import theme from './ThemeContext';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  }
};

const Layout = ({ children, classes }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={({ site }) => (
      <ThemeProvider theme={theme}>
        <div className={layoutStyles.outerContainer}>
          <div className={layoutStyles.contentSection}>
            <div className={classes.root}>
              <AppBar position="static" color="primary">
                <Grid container justify="center">
                  <Grid item xs={10}>
                    <Toolbar>
                      <Typography variant="h6" color="inherit" className={classes.flex}>
                        <Link to="/">
                          <img src={Logo} alt="Replaix logo (white)" height="30"/>
                        </Link>
                      </Typography>
                      <Hidden xsDown>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          component="a"
                          href="/app/auth/sign-up"
                        >
                          Sign Up
                        </Button>
                      </Hidden>
                      <Button
                        component="a"
                        href="/app/auth/login"
                        color="inherit"
                      >
                        Login
                      </Button>
                    </Toolbar>
                  </Grid>
                </Grid>
              </AppBar>
            </div>
            <Grid container>
              {children}
            </Grid>
          </div>
          <div className={layoutStyles.footer}>
            <Grid
              container
              className={layoutStyles.footer_container}>
              <Grid item sm={3} xs={10}>
                <div className={layoutStyles.footer_column}>
                  <Typography variant="h6">Company</Typography>
                  <Typography variant="body2">
                    <Link to="/about">About</Link>
                  </Typography>
                  <Typography variant="body2">
                    <Link to="/blog">Blog</Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={3} xs={10}>
                <div className={layoutStyles.footer_column}>
                  <Typography variant="h6">Platform</Typography>
                  <Typography variant="body2">
                    <Link to="/app/auth/login">App</Link>
                  </Typography>
                  <Typography variant="body2">
                    <Link to="/pricing">Pricing</Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={3} xs={10}>
                <div className={layoutStyles.footer_column}>
                  <Typography variant="h6">Resources</Typography>
                  <Typography variant="body2">
                    <Link to="/contact">Contact</Link>
                  </Typography>
                  <Typography variant="body2">
                    <a href={process.env.GATSBY_DISCORD_CHANNEL_LINK} target="_blank" rel="noopener noreferrer">Discord</a>
                  </Typography>
                  <Typography variant="body2">
                    <Link to="/privacy">Privacy</Link>
                  </Typography>
                  <Typography variant="body2">
                    <Link to="/terms">Terms</Link>
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12} className={layoutStyles.footer_logo}>
                <Link to="/">
                  <img src={LogoBlack} alt="Replaix logo (black)" height="20"/>
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(Layout);