import React from 'react';

import { INLINES } from '@contentful/rich-text-types';
import { Link } from 'gatsby';

export function filterObjectByKeys(obj: Object, ...keys: Array<string|number>) {
  return keys.reduce((acc, key) => {
    if(obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
}

export function pluralize(word: string, number: number, plural?: string) {
  if(number === 1) {
    return word;
  }

  return plural || word + 's';
}

export function mergeClasses(...classes) {
  return classes
    .filter(Boolean)
    .join(' ');
}

export function mapFirestoreDoc(doc) {
  return {
    id: doc.id,
    ...doc.data()
  };
}

export const contentfulRenderOptions = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri: href } = node.data;

      if(href.indexOf('http') > -1) {
        return <a href={href}>{children}</a>;
      }

      return <Link to={href}>{children}</Link>
    }
  }
};