import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';

// config format found here
//  https://material-ui.com/customization/default-theme/

export default createMuiTheme({
  palette: {
    primary: {
      main: '#283593'
    },
    secondary: {
      main: '#d81b60'
    }
  },
  // status: {
  //   error: '#f44336',
  //   success: '#00c853'
  // },
  typography: {
    fontFamily: '"Overpass", sans-serif',
    h1: {
      fontFamily: '"Chivo", serif'
    },
    h2: {
      fontFamily: '"Chivo", serif'
    },
    h3: {
      fontFamily: '"Chivo", serif',
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: '15px'
    },
    h4: {
      fontFamily: '"Chivo", serif',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h5: {
      fontFamily: '"Chivo", serif'
    },
    h6: {
      fontFamily: '"Chivo", serif'
    },
    subtitle1: {
      fontFamily: '"Chivo", serif'
    },
  }
});